import Slider from 'react-slick';
import Image from 'next/image';

import { partners } from '../../consts/consts';

export const Partners: React.FC = (): JSX.Element => {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {partners.map((item, index) => (
        <div key={index} className="inline-block align-middle">
          <Image
            src={`/img/partners/${item.name}.png`}
            width={251}
            height={181}
            alt={item.name}
            priority={true}
            className="-mt-3 sm:mt-0 px-12 pb-6"
          />
        </div>
      ))}
    </Slider>
  );
};
