
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticProps, NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Carousel from 'framer-motion-carousel';
import { NextSeo } from 'next-seo';
import { HeroProducts } from '../components/hero-products';
import { Partners } from '../components/partners';
import { About } from '../components/about';
import { Promotion } from '../components/promotion';
import { getAllPosts } from '../utils/getPosts';
import { News } from '../components/news';
import { getHomePage } from '@utils/getHomePage';
import { TAbout, TBanner, TSlide } from '@type/home';
import React from 'react';
import Link from 'next/link';
type IPost = {
    posts: {
        databaseId: number;
        slug: string;
        title: string;
        content: string;
        date: string;
        featuredImage: {
            node: {
                sourceUrl: string;
            };
        };
    }[];
    slides: TSlide[];
    about: TAbout;
    banners: TBanner[];
};
const Index: NextPage<IPost> = ({ posts, slides, about, banners }) => {
    const { t, lang } = useTranslation('common');
    // const obnizka = lang === 'it' ? 'obnizka55it' : 'obnizka55';
    // const slider =
    //   lang === 'pl'
    //     ? [
    //         'pergole-balustrady',
    //         'obnizka-KNS-8',
    //         'obnizkaHSInowa',
    //         // 'KNSwspieraMMA',
    //         'obnizka18',
    //         obnizka,
    //         'okno',
    //         'slide1',
    //         'slide2',
    //       ]
    //     : ['slide1', 'slide2'];
    return (<>
      <NextSeo languageAlternates={[
            { hrefLang: 'x-default', href: 'https://knsokna.pl' },
            { hrefLang: 'en', href: 'https://knswindows.com' },
            { hrefLang: 'pl', href: 'https://knsokna.pl' },
            { hrefLang: 'de', href: 'https://knsfenster.de' },
            { hrefLang: 'cs', href: 'https://kns-okna.eu' },
            { hrefLang: 'it', href: 'https://knsfinestre.it' },
            { hrefLang: 'fr', href: 'https://knsfenetres.fr' },
            { hrefLang: 'hu', href: 'https://knsokna.eu' },
        ]}/>
      <div className="sm:-mt-4 inset-0 relative">
        <div className="h-[calc(100svh)] w-screen -mt-12 sm:-mt-28">
          <Carousel autoPlay={true} interval={5000} loop={true}>
            {slides.map((item, i) => (<React.Fragment key={i}>
                {item.url ? (<Link href={item.url}>
                    <Image src={item?.mobileimg?.sourceUrl} key={`${i}m`} quality={100} priority={true} width="500" height="800" className="object-cover h-full w-full block md:hidden" style={i === 1 ? { objectPosition: '50%' } : {}} alt={'slide'}/>
                    <Image src={item?.desktopimg?.sourceUrl} key={i} priority={true} width="1920" height="1200" className="object-cover h-full w-full hidden md:block" style={i === 1 ? { objectPosition: '50%' } : {}} alt={'slide'}/>
                  </Link>) : (<>
                    <Image src={item?.mobileimg?.sourceUrl} key={`${i}m`} quality={100} priority={true} width="500" height="800" className="object-cover h-full w-full block md:hidden" style={i === 1 ? { objectPosition: '50%' } : {}} alt={'slide'}/>
                    <Image src={item?.desktopimg?.sourceUrl} key={i} priority={true} width="1920" height="1200" className="object-cover h-full w-full hidden md:block" style={i === 1 ? { objectPosition: '50%' } : {}} alt={'slide'}/>{' '}
                  </>)}
              </React.Fragment>))}
          </Carousel>
        </div>
        {/* <div className="hidden sm:flex h-wscreen z-10 absolute bottom-1/4">
          <div className="m-auto p-4 text-white">
            <div className="ml-0 md:ml-96 md:mt-24 p-3 bg-black bg-opacity-75">
              <h1 className="text-4xl">{t('home.title')}</h1>
              <span className="text-xl block">{t('home.subtitle')}</span>
            </div>
          </div>
        </div> */}
        <div className="absolute bottom-0 bg-white w-screen h-24 sm:h-28">
          <div className="max-w-7xl mx-auto mt-1">
            <Partners />
          </div>
        </div>
      </div>
      <div className="m-auto px-4 py-8 text-black text-center">
        <div className="p-3">
          <h1 className="text-4xl">{t('home.title')}</h1>
          <h2 className="text-xl block">{t('home.subtitle')}</h2>
        </div>
      </div>
      <HeroProducts />
      <About {...about}/>
      <Promotion banners={banners}/>
      {/* <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 text-center">
            <Image
              src="/img/kns-w-liczbach.png"
              alt="mapa"
              width="1214"
              height="382"
            />
          </div>
        </div> */}
      <News posts={posts}/>
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">{t('home.findProducts1')}</span>
            <span className="block text-indigo-600">
              {t('home.findProducts2')}
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="ml-3 inline-flex rounded-md shadow">
              <a href="#" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">
                {t('seeMore')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50">
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <Image src="/img/swiat.jpg" alt="mapa" width="1928" height="1110"/>
          {/* <div className="mt-8 flex flex-col lg:mt-0 lg:flex-shrink-0">
          {languages.flatMap((item, index) => (
            <div
              className="ml-3 inline-flex rounded-md shadow my-2"
              key={index}
            >
              <a
                href="#"
                className="w-100 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
              >
                <img
                  src={`/img/locales/${item.locale}.svg`}
                  width="24"
                  height="24"
                  className="rounded-full drop-shadow-md mr-3"
                />
                {item.name}
              </a>
            </div>
          ))}

        </div> */}
        </div>
      </div>
    </>);
};
const getStaticProps: GetStaticProps = async (context) => {
    const locale = context.locale || 'PL';
    const language = locale === 'cz' ? 'CS' : locale.toUpperCase();
    const { allPosts } = await getAllPosts(language, 3);
    const posts = allPosts.posts.nodes;
    const { slides, about, banners } = await getHomePage(language);
    return {
        props: {
            posts,
            slides,
            about,
            banners,
        },
    };
};
export default Index;

    async function __Next_Translate__getStaticProps__193ad5efd98__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193ad5efd98__ as getStaticProps }
  