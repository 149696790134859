import { TAbout } from '@type/home';
import Image from 'next/image';

export const About = ({ image, title, description }: TAbout) => {
  return (
    <div className="relative px-2 py-4">
      <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row">
        <div className="w-100 lg:w-1/2 text-center m-auto">
          <Image
            src={image?.sourceUrl}
            width={570}
            height={320}
            alt={image?.sourceUrl}
            className="my-auto"
          />
        </div>
        <div className="bg-gradient-to-b from-red to-orange w-2 mx-5"></div>
        <div className="w-100 lg:w-1/2 py-5">
          <h3 className="text-4xl">{title}</h3>
          <br />
          <br />
          <div
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      </div>
    </div>
  );
};
