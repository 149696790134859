import Image from 'next/image';
import Link from 'next/link';

type TBanners = {
  banners: {
    url: string;
    img: {
      sourceUrl: string;
      altText: string;
    };
  }[];
};

export const Promotion = ({ banners }: TBanners) => {
  return (
    <div className="relative px-1 sm:px-2 py-4">
      <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row gap-10 md:gap-0 py-4">
        {banners.map((item, index) => (
          <div
            className="relative w-full lg:w-1/2 lg:m-4 overflow-hidden"
            key={index}
          >
            <Link href={item.url} aria-label={item.img.altText}>
              <Image
                src={item.img.sourceUrl}
                width={600}
                height={300}
                alt={item.img.altText}
                className="w-full hover:scale-105 transition-all duration-500 ease-in-out"
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
