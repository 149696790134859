import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';

import styles from './hero-products.module.scss';

export function HeroProducts() {
  const { t } = useTranslation('common');
  const pvc = t('products.pvc').split(' ');
  const pvcInit = pvc
    .slice()
    .splice(0, pvc.length - 1)
    .join(' ');
  const pvcPop = pvc.slice().splice(-1);
  const pvcName =
    pvc.length > 1
      ? `${pvcInit} <span>${pvcPop}</span>`
      : `<span>${pvc[0]}</span>`;

  const alu = t('products.alu').split(' ');
  const AluInit = alu
    .slice()
    .splice(0, alu.length - 1)
    .join(' ');
  const aluPop = alu.slice().splice(-1);
  const aluName =
    alu.length > 1
      ? `${AluInit} <span>${aluPop}</span>`
      : `<span>${alu[0]}</span>`;

  const rollers = t('products.rollers').split(' ');
  const rollersInit = rollers
    .slice()
    .splice(0, rollers.length - 1)
    .join(' ');
  const rollersPop = rollers.slice().splice(-1);
  const rollersName =
    rollers.length > 1
      ? `${rollersInit} <span>${rollersPop}</span>`
      : `<span>${rollers[0]}</span>`;

  const pergolas = t('products.pergolas').split(' ');
  const pergolasInit = pergolas
    .slice()
    .splice(0, pergolas.length - 1)
    .join(' ');
  const pergolasPop = pergolas.slice().splice(-1);
  const pergolasName =
    pergolas.length > 1
      ? `${pergolasInit} <span>${pergolasPop}</span>`
      : `<span>${pergolas[0]}</span>`;
  return (
    <div className="bg-white relative px-1 sm:px-2">
      <div className="max-w-screen-2xl mx-auto py-4">
        <div
          id="main"
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 justify-evenly"
        >
          <div className={`w-26 p-1 ${styles['item']}`}>
            <figure className={`${styles['effect']}`}>
              <Image src="/img/pvc.jpg" alt=".." width={640} height={462} />
              <figcaption>
                <div>
                  <h2 dangerouslySetInnerHTML={{ __html: pvcName }}></h2>
                  {/* <p>{t('products.pvcDesc')}</p> */}
                </div>
                <Link href="/pvc">{t('products.pvc')}</Link>
              </figcaption>
            </figure>
          </div>
          <div className={`w-26 p-1 ${styles['item']}`}>
            <figure className={styles['effect']}>
              <Image src="/img/alu.jpg" alt=".." width={640} height={462} />
              <figcaption>
                <div>
                  <h2 dangerouslySetInnerHTML={{ __html: aluName }}></h2>
                  {/* <p>{t('products.aluDesc')}</p> */}
                </div>
                <Link href="/alu">{t('products.alu')}</Link>
              </figcaption>
            </figure>
          </div>
          <div className={`w-26 p-1 ${styles['item']}`}>
            <figure className={styles['effect']}>
              <Image src="/img/rollers.jpg" alt=".." width={640} height={462} />
              <figcaption>
                <div>
                  <h2 dangerouslySetInnerHTML={{ __html: rollersName }}></h2>
                  {/* <p>{t('products.rollersDesc')}</p> */}
                </div>
                <Link href="/rollers">{t('products.rollers')}</Link>
              </figcaption>
            </figure>
          </div>
          <div className={`w-26 p-1 ${styles['item']}`}>
            <figure className={styles['effect']}>
              <Image
                src="/img/pergolas.jpg"
                alt=".."
                width={640}
                height={462}
              />
              <figcaption>
                <div>
                  <h2 dangerouslySetInnerHTML={{ __html: pergolasName }}></h2>
                  {/* <p>{t('products.pergolasDesc')}</p> */}
                </div>
                <Link href="/pergolas">{t('products.pergolas')}</Link>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroProducts;
